/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingDots } from '../LoadingDots/LoadingDots';
import { MatchScore } from '../MatchScore/MatchScore';
import { SearchProgressDeveloperView } from '@/utils/developerMode';
import { SearchProgress, SearchResult } from '@/types/api';
import { Icons } from '@/assets';

export type SearchStatusType = SearchResult['progress']['stage'];

const DebounceValue = ({
  targetValue,
  increment,
  time,
}: {
  targetValue: number;
  increment: number;
  time: number;
}) => {
  const [currentValue, setCurrentValue] = useState(targetValue);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (targetValue !== currentValue) {
      interval = setInterval(() => {
        setCurrentValue((currentValue) => Math.min(currentValue + increment, targetValue));
        if (currentValue >= targetValue) {
          clearInterval(interval);
          interval = undefined;
        }
      }, time);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [targetValue]);

  return (
    <p className="text-xs text-center py-0.5 min-w-9 rounded text-qura-neutral-jet bg-qura-neutral-ghost px-2">
      {new Intl.NumberFormat('en-US', {
        style: 'decimal',
        useGrouping: true,
        minimumFractionDigits: 0,
      })
        .format(Math.ceil(currentValue))
        .replace(/,/g, ' ')}
    </p>
  );
};

type SearchStatusProps = {
  foundDocuments: number;
  progress: SearchProgress | null;
};

const SearchStatus: FC<SearchStatusProps> = ({ foundDocuments, progress }) => {
  const { t } = useTranslation();

  return (
    <div className="p-6 h-fit pointer-events-auto flex flex-col rounded-md shadow-qura bg-white text-xs w-filter relative group">
      <div className="flex items-center justify-between mb-4">
        <p className="text-xs mt-0.5 text-qura-neutral-jet">{t('searchStatus.searchScore')}</p>
        <MatchScore
          score={progress?.searchScore === 0 ? null : (progress?.searchScore ?? null)}
          className="text-xs text-center w-9 py-0.5 rounded pl-0.5"
        />
      </div>
      <div className="flex items-center justify-between mb-4">
        <p className="text-xs mt-0.5 text-qura-neutral-jet">{t('searchStatus.documentsFound')}</p>
        <p className="text-xs text-center w-9 py-0.5 bg-qura-neutral-ghost rounded text-qura-neutral-jet">
          {foundDocuments}
        </p>
      </div>

      <div className="flex items-center justify-between mb-4">
        <p className="text-xs mt-0.5 text-qura-neutral-jet">
          {t('searchStatus.documentsSearched')}
        </p>
        <DebounceValue
          targetValue={progress?.searchedDocuments ?? 0}
          increment={
            progress?.stage === 'done'
              ? (progress?.searchedDocuments ?? 0)
              : (progress?.searchedDocuments ?? 0) / 50
          }
          time={progress?.searchedDocuments ? 50 : 0}
        />
      </div>

      <div className="h-4" />
      <div className="flex flex-col gap-3">
        {/** remove *any* once new type is supported by backend */}
        {Object.values((progress as any)?.stages || {})
          .sort((a: any, b: any) => {
            return b.weight - a.weight;
          })
          .map((stage: any) => {
            return (
              <ChainLink key={stage.name} title={t(stage.name)} chainStatus={t(stage.status)} />
            );
          })}
      </div>
      <SearchProgressDeveloperView data={progress} />
    </div>
  );
};

const ChainLink: FC<{
  title: string;
  chainStatus: 'pending' | 'completed' | 'ongoing';
}> = ({ chainStatus, title }) => {
  const { t } = useTranslation();

  const getOuterCircleStyles = () => {
    const regularStyles = 'flex items-center justify-center rounded-full w-7 h-7 border-2 ';

    let changedStyles = '';

    switch (chainStatus) {
      case 'ongoing':
        changedStyles = 'bg-qura-neutral-ghost  border-qura-neutral-ghost';
        break;
      case 'completed':
        changedStyles = 'bg-qura-neutral-jet border-qura-neutral-jet';
        break;
      case 'pending':
        changedStyles = 'bg-qura-neutral-ghost border-qura-neutral-ghost';
        break;
    }
    return `${regularStyles} ${changedStyles}`;
  };

  const getInnerCircleStyles = () => {
    const regularStyles = 'flex items-center justify-center rounded-full';

    let changedStyles = '';

    switch (chainStatus) {
      case 'ongoing':
        changedStyles = 'w-5 h-5 bg-qura-neutral-ghost';
        break;
      case 'completed':
        changedStyles = '';
        break;
      case 'pending':
        changedStyles = 'w-5 h-5 bg-qura-neutral-ghost';
        break;
    }

    return `${regularStyles} ${changedStyles}`;
  };

  const renderStatus = () => {
    switch (chainStatus) {
      case 'ongoing':
        return t('searchStatus.ongoing');

      case 'completed':
        return t('searchStatus.completed');

      case 'pending':
        return t('searchStatus.pending');
    }
  };

  return (
    <div className="flex flex-col items-start justify-start mb-2">
      <div className="flex items-center h-10 gap-3">
        <div className={getOuterCircleStyles()}>
          <div className={getInnerCircleStyles()}>
            {chainStatus === 'ongoing' && <LoadingDots />}
            {chainStatus === 'completed' && <Icons.Checked className="text-white" />}
          </div>
        </div>
        <div className="">
          <p className={'text-sm'}>{title}</p>
          <p className="text-xs text-qura-neutral-balanced font">{renderStatus()}</p>
        </div>
      </div>
    </div>
  );
};

export default SearchStatus;
