import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';
import { useParams } from 'react-router-dom';
import { PDFPreview } from '../PDFPreview/PDFPreview';
import { MatchScore } from '../MatchScore/MatchScore';
import { ResultDocument } from '@/types/api';
import { Icons } from '@/assets';
import { POSTHOG_EVENT } from '@/services/posthog/events';
import { DocumentDeveloperView } from '@/utils/developerMode';

type Props = {
  resultDocument: ResultDocument;
  scrollIntoView?: boolean;
  willBeRemoved?: boolean;
  onOpen?: (document: ResultDocument, chunk: number) => void;
};

const EXIT_DEBOUNCE_TIME = 5_000;
const CLOSED_CHUNK_HEIGHT = 128;
const CLOSED_TITLE_HEIGHT = 24;
const TAG_ROW_HEIGHT = 20;

const BLACKLISTED_TAGS = [
  'Rättspraxis',
  'Allmän domstol',
  'Specialdomstolar',
  'Hovrätt',
  'Förvaltningsdomstol',
  'Branchreglering',
  'Myndigheter',
  'Övrigt',
];

export const SearchResult: FC<Props> = ({ resultDocument: doc, onOpen, willBeRemoved }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [hasLoadedPreview, setHasLoadedPreview] = useState(false);
  const posthog = usePostHog();

  const { searchId = '' } = useParams();

  const chunkContainerRef = useRef<HTMLDivElement>(null);
  const titleContainerRef = useRef<HTMLDivElement>(null);
  const tagContainerRef = useRef<HTMLDivElement>(null);
  const [titleContainerHeight, setTitleContainerHeight] = useState(0);
  const [chunkContainerHeight, setChunkContainerHeight] = useState(0);
  const [tagContainerHeight, setTagContainerHeight] = useState(0);
  const debounceTimeRef = useRef<NodeJS.Timeout | null>(null);

  const filteredTags = useMemo(() => {
    return doc.tags.filter((tag) => !BLACKLISTED_TAGS.includes(tag));
  }, [doc.tags]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === chunkContainerRef.current) {
          setChunkContainerHeight(entry.contentRect.height);
        } else if (entry.target === titleContainerRef.current) {
          setTitleContainerHeight(entry.contentRect.height);
        } else if (entry.target === tagContainerRef.current) {
          setTagContainerHeight(entry.contentRect.height);
        }
      });
    });

    if (chunkContainerRef.current) {
      resizeObserver.observe(chunkContainerRef.current);
    }
    if (titleContainerRef.current) {
      resizeObserver.observe(titleContainerRef.current);
    }

    if (tagContainerRef.current) {
      resizeObserver.observe(tagContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [chunkContainerRef, titleContainerRef, tagContainerRef]);

  const onExitScreen = () => {
    if (debounceTimeRef.current) {
      return;
    }
    debounceTimeRef.current = setTimeout(() => {
      debounceTimeRef.current = null;
      setOpen(false);
      setHasLoadedPreview(false);
      setIsVisible(false);
    }, EXIT_DEBOUNCE_TIME);
  };

  const onEnterScreen = () => {
    if (debounceTimeRef.current) {
      clearTimeout(debounceTimeRef.current);
      debounceTimeRef.current = null;
    }
    setIsVisible(true);
  };

  useEffect(() => {
    const verticalLoadMargin = window.innerHeight / 2;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target === chunkContainerRef.current) {
            if (entry.isIntersecting) {
              onEnterScreen();
            } else {
              onExitScreen();
            }
          }
        });
      },
      {
        root: null,
        rootMargin: `${verticalLoadMargin}px 0px ${verticalLoadMargin}px 0px`,
        threshold: 0.1,
      },
    );

    if (chunkContainerRef.current) {
      observer.observe(chunkContainerRef.current);
    }

    return () => {
      observer.disconnect();
      if (debounceTimeRef.current) {
        clearTimeout(debounceTimeRef.current);
      }
    };
  }, []);

  const onClick = () => {
    if (!hasLoadedPreview) return;
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      return;
    }

    posthog.capture(POSTHOG_EVENT.SEARCH_RESULT_EXPAND, {
      relevanceScore: doc.relevanceScore,
      documentId: doc.documentId,
      searchId,
    });
    setOpen((open) => !open);
  };

  const onOpenPdf = () => {
    if (onOpen) {
      onOpen(doc, 0);
    }
  };

  const onOpenPdfClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onOpenPdf();
  };

  const firstChunk = doc.outputChunks[0];
  if (!firstChunk) return;

  return (
    <div
      className={`group relative flex flex-col bg-white shadow-qura rounded-lg cursor-pointer`}
      onClick={onClick}>
      <div className={`px-4 pt-4`}>
        <div
          className="flex items-start gap-2 transition-height overflow-hidden"
          style={{ height: open ? tagContainerHeight : TAG_ROW_HEIGHT }}>
          <div
            className="flex relative items-start flex-wrap flex-1 gap-[6px] overflow-hidden"
            ref={tagContainerRef}>
            {doc.legalId && (
              <p
                className="flex bg-qura-neutral-ghost rounded pr-2 py-0.5 overflow-hidden max-w-[50%]"
                title={doc.legalId}>
                <Icons.Document className="mt-[0.5px] ml-[6px] flex-shrink-0" />
                <p className="ml-1 text-xs font-medium whitespace-nowrap text-ellipsis overflow-hidden flex-shrink">
                  {doc.legalId}
                </p>
              </p>
            )}
            {doc.year && (
              <div className="flex bg-qura-neutral-ghost flex-shrink-0 rounded px-2 py-0.5">
                <p className="text-xs">
                  {doc.month && doc.day
                    ? `${doc.year}-${String(doc.month).padStart(2, '0')}-${String(doc.day).padStart(2, '0')}`
                    : doc.year}
                </p>
              </div>
            )}
            {filteredTags.map((tag, i) => (
              <div
                className="flex bg-qura-neutral-ghost flex-shrink-0 rounded px-2 py-0.5"
                key={tag + i}>
                <p className="text-xs">{tag}</p>
              </div>
            ))}
          </div>
          <div className="flex items-center">
            <p className="text-[11px] text-gray-600 mr-1">{t('searchResult.searchMatch')}</p>
            <MatchScore score={doc.relevanceScore} className="text-tiny px-1.5 py-0.5 rounded" />
          </div>
        </div>

        <div
          className="relative transition-height overflow-hidden my-2"
          style={{
            height: open
              ? Math.max(titleContainerHeight, CLOSED_TITLE_HEIGHT)
              : CLOSED_TITLE_HEIGHT,
          }}>
          <p ref={titleContainerRef} className="absolute text-[16px]">
            {doc.title}
          </p>
          {!open && titleContainerHeight > CLOSED_TITLE_HEIGHT && (
            <p
              className="absolute right-0 top-0 bottom-0 text-right w-10 pr-2 text-[16px] font-semibold"
              style={{
                background: 'linear-gradient(to left, white 65%, transparent)',
                backgroundPosition: '5%',
              }}>
              ...
            </p>
          )}
        </div>
        <div className="h-[1px] bg-gray-200" />
      </div>

      <div
        className={`relative flex flex-col items-center overflow-hidden my-4 transition-height`}
        style={{
          height: open ? chunkContainerHeight : Math.min(CLOSED_CHUNK_HEIGHT, chunkContainerHeight),
          minHeight: CLOSED_CHUNK_HEIGHT,
        }}>
        <div className="absolute z-0 w-full" ref={chunkContainerRef}>
          <PDFPreview
            chunk={firstChunk}
            loadingLogoHeight={CLOSED_CHUNK_HEIGHT}
            inactive={!isVisible || !firstChunk.fileExcerptPointer}
            scale={1.2}
            width={firstChunk.excerptWidth ?? undefined}
            adjustColorToScore={doc.relevanceScore}
            hasLoaded={hasLoadedPreview}
            onLoad={() => setHasLoadedPreview(true)}
          />
        </div>
        {!open && chunkContainerHeight > CLOSED_CHUNK_HEIGHT && (
          <div className="absolute w-full bottom-0 h-16 bg-gradient-to-b to-white from-transparent rounded-b-lg" />
        )}
        <div className="absolute right-6 top-3 bg-qura-neutral-ghost px-2 py-1 rounded text-xs">
          {t('searchResult.pageNumberShort') + ' '}
          {firstChunk.pageNumber + 1}
        </div>

        <div
          className="absolute flex items-center right-6 bottom-3 bg-qura-neutral-ghost px-2 py-1 border border-white shadow rounded cursor-pointer hover:bg-neutral-200 hover:border-gray-200"
          onClick={onOpenPdfClick}>
          <Icons.Document className="mr-1 h-[14px]" />
          <p className="text-xs">{t('searchResult.openPdf')}</p>
        </div>
      </div>
      {!open && hasLoadedPreview && (
        <div className="absolute z-10 self-center bottom-[1px] text-xs text-neutral-400 trailing-wide opacity-0 group-hover:opacity-100">
          {open ? t('searchResult.clickToMinimize') : t('searchResult.clickToExpand')}
        </div>
      )}
      <div
        className={`absolute top-0 left-0 w-full h-full bg-white rounded-lg pointer-events-none ${willBeRemoved ? 'animate-fade-in' : 'animate-fade-out-fast'}`}
      />
      <DocumentDeveloperView data={doc} />
    </div>
  );
};
