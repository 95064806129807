import { usePostHog } from 'posthog-js/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingResultCard } from './LoadingResultCard';
import { __temp_mockStages } from './__temp_mockStages';
import { Filtering } from '../../components/Filtering/Filtering';
import Layout from '../../components/Layout/Layout';
import { SearchResult } from '../../components/SearchResult/SearchResult';
import SearchStatus from '../../components/SearchStatus/SearchStatus';
import { createNewSearch } from '../../state/actions/createNewSearch';
import { loadOldSearch, setParams } from '../../state/actions/searchParams';
import { sendSearchParams } from '../../state/actions/sendSearchParams';
import { useSearchStore } from '../../state/searchStore';
import LoadingGradient from '../../components/LoadingGradient/LoadingGradient';
import { useDebounceResults } from '../../state/hooks/useDebounceResults';
import { ResultDocument } from '@/types/api';
import { POSTHOG_EVENT } from '@/services/posthog/events';
import { getOldSearch } from '@/services/firebase/functions/getOldSearch';
import { sendErrorLog } from '@/services/firebase/functions/sendErrorLog';
import { useAvailableFilterTags } from '@/services/api/actions/getAvailableFilterTags';
import DatabaseInfoModal from '@/features/Search/components/DatabaseInfoModal/DatabaseInfoModal';
import { Icons } from '@/assets';
import devlog from '@/utils/devlog';

type ResultPageStatus =
  | 'idle'
  | 'searching'
  | 'search-error'
  | 'loading-old-search'
  | 'old-search-error';

const RESULT_PAGE_SCROLL = 'resultPageScroll';
const LOADING_CARDS = 20;

export const SearchResultPage = () => {
  const { searchId = '' } = useParams();

  const posthog = usePostHog();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedDataRole = useSearchStore((s) => s.currentParams.dataRole);
  const { data: tagsFilter } = useAvailableFilterTags(selectedDataRole);
  const [hasTriedToLoadOldSearch, setHasTriedToLoadOldSearch] = useState(false);
  const query = useSearchStore((state) => state.currentParams.query);
  const hasSearch = useSearchStore((state) => searchId in state.searches);

  const progress = useSearchStore((state) => state.searches[searchId]?.results?.progress ?? null);
  const { resultsToShow, resultsFetched, noticeWillBeRemoved, waitingForResults } =
    useDebounceResults(searchId);

  const [isDataBaseInfoModalOpened, setIsDataBaseInfoModalOpened] = useState(false);

  const status = useMemo<ResultPageStatus>(() => {
    if (hasSearch) {
      if (waitingForResults) {
        return 'searching';
      }
    } else {
      if (hasTriedToLoadOldSearch) {
        return 'old-search-error';
      } else {
        return 'loading-old-search';
      }
    }
    return 'idle';
  }, [hasSearch, hasTriedToLoadOldSearch, waitingForResults]);

  useEffect(() => {
    const hasSearch = searchId in useSearchStore.getState().searches;

    if (searchId && !hasSearch) {
      setHasTriedToLoadOldSearch(false);
      getOldSearch(searchId)
        .then(({ result, submittedParams }) => {
          result.progress.stage = 'done';
          loadOldSearch(searchId, result, submittedParams);
          setParams(submittedParams);
          setHasTriedToLoadOldSearch(true);
        })
        .catch((err) => {
          console.error('Error fetching old search:', err);
          sendErrorLog(err);
          devlog('Error loading old search:', err);
          setHasTriedToLoadOldSearch(true);
        });
    }
  }, [searchId]);

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem(RESULT_PAGE_SCROLL);
    const scrollY = (() => {
      try {
        if (scrollPosition) {
          const { _searchId, _scrollY } = JSON.parse(scrollPosition);
          if (_searchId === searchId) {
            return _scrollY;
          }
          sessionStorage.removeItem(RESULT_PAGE_SCROLL);
        }
        return 0;
      } catch (error) {
        devlog('Error parsing scroll position:', error);
        sessionStorage.removeItem(RESULT_PAGE_SCROLL);
        return 0;
      }
    })();
    window.scrollTo(0, scrollY);
  }, [searchId]);

  const onSearch = () => {
    createNewSearch()
      .then(({ searchId }) => {
        sendSearchParams(searchId);
        navigate(`/search/${searchId}`);
      })
      .catch(() => {});
  };

  const onOpen = (document: ResultDocument, chunkIndex: number) => {
    const chunk = document.outputChunks[chunkIndex];
    posthog.capture(POSTHOG_EVENT.SEARCH_RESULT_OPEN, {
      chunk: true,
      relevanceScore: document.relevanceScore,
      documentId: document.documentId,
      searchId,
    });
    sessionStorage.setItem(
      RESULT_PAGE_SCROLL,
      JSON.stringify({ _searchId: searchId, _scrollY: window.scrollY }),
    );
    navigate(
      `${document.documentId}?page=${chunk.pageNumber}&offset=${Math.floor(chunk.heightOffset)}`,
    );
  };

  const onTryAgain = () => {
    posthog.capture(POSTHOG_EVENT.SEARCH_TRY_AGAIN, { searchId });
    onSearch();
  };

  const onGoBack = () => {
    posthog.capture(POSTHOG_EVENT.NAVIGATE_BACK_HOME, { searchId });
    navigate('/');
  };

  const handleDataBaseInfoModalStatus = () => {
    setIsDataBaseInfoModalOpened(!isDataBaseInfoModalOpened);
  };

  const selectDocument = (documentId: string) => {
    const doc = resultsFetched.find((doc) => doc.documentId === documentId);
    posthog.capture(POSTHOG_EVENT.SEARCH_RESULT_OPEN, {
      relevanceScore: doc?.relevanceScore,
      documentId,
      searchId,
    });
    navigate(`/document/${documentId}`);
  };

  return (
    <Layout
      query={query}
      onSearch={onSearch}
      selectDocument={selectDocument}
      contentClassName="w-[calc(100%-700px)] max-w-[850px] min-w-[75%] lg:min-w-[600px] "
      leftContent={() =>
        tagsFilter ? (
          <div className="flex flex-col items-stretch h-full overflow-y-scroll px-2 -mx-2 py-1 -my-1">
            <Filtering searchId={searchId} tagsFilter={tagsFilter} />
            <button
              onClick={handleDataBaseInfoModalStatus}
              className="flex text-xs leading-tight hover:underline self-center mt-3 mb-3 bg-qura-bg px-4 py-2 rounded-full flex-shrink-0">
              <Icons.Info className="text-black mr-1 mt-[1px]" />
              <p>{t('databaseInformation.databaseInformation')}</p>
            </button>
            <div className="h-16 flex-shrink-0" />
          </div>
        ) : (
          <div className="flex flex-col w-filter items-center py-5 gap-5 bg-white shadow-qura rounded">
            {Array.from({ length: 10 }).map((_, index) => (
              <LoadingGradient className="w-[90%] h-3 opacity-40 rounded-sm" key={index} />
            ))}
          </div>
        )
      }
      rightContent={() => (
        <SearchStatus
          foundDocuments={resultsFetched.length}
          progress={__temp_mockStages(progress)}
        />
      )}>
      {['search-error'].includes(status) && (
        <div className="flex flex-col gap-2 items-start h-full">
          <p className="text-xs">{t('errors.unknown')}</p>
          <button
            className="bg-white border border-qura-neutral-silver text-qura-neutral-jet hover:bg-qura-neutral-ghost text-xs px-2 py-1.5 rounded"
            onClick={onTryAgain}>
            {t('common.tryAgain')}
          </button>
        </div>
      )}

      {['old-search-error'].includes(status) && (
        <div className="flex flex-col gap-2 items-start h-full">
          <p className="text-xs">{t('errors.invalidSearchId')}</p>
          <button
            className="bg-white border border-qura-neutral-silver text-qura-neutral-jet hover:bg-qura-neutral-ghost text-xs px-2 py-1.5 rounded"
            onClick={onGoBack}>
            {t('searchResult.newSearch')}
          </button>
        </div>
      )}

      <div className="flex flex-col gap-6 pb-20">
        {['idle', 'searching'].includes(status) && resultsFetched.length > 0
          ? resultsToShow.map((result, i) => (
              <SearchResult
                resultDocument={result}
                onOpen={onOpen}
                key={result.documentId}
                willBeRemoved={
                  noticeWillBeRemoved && result.documentId !== resultsFetched[i]?.documentId
                }
              />
            ))
          : status == 'idle' && <p className="text-xs">{t('searchResult.noSearchResults')}</p>}

        {(['loading-old-search', 'searching'].includes(status) ||
          resultsFetched.length !== resultsToShow.length) &&
          Array(LOADING_CARDS)
            .fill(undefined)
            .map((_, index) => (
              <LoadingResultCard
                key={index}
                willBeRemoved={
                  noticeWillBeRemoved && resultsToShow.length + index < resultsFetched.length
                }
              />
            ))}
      </div>

      {isDataBaseInfoModalOpened && <DatabaseInfoModal onClose={handleDataBaseInfoModalStatus} />}
    </Layout>
  );
};
