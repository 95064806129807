import { useFilterHasChanged } from '../../state/hooks/useFilterHasChanged';
import { useSearchStore } from '../../state/searchStore';

export const FilterBadge = ({ searchId }: { searchId: string }) => {
  const tagIds = useSearchStore((state) => state.currentParams.filter.tagIds);
  const hasChanged = useFilterHasChanged(searchId);

  if (!tagIds.length) return null;

  return (
    <div className="absolute right-2 -top-2 size-5 flex items-center justify-center rounded-full text-center text-xs bg-qura-neutral-jet text-white font-extrabold">
      {hasChanged ? '!' : tagIds.length}
    </div>
  );
};
