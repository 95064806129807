import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '@/assets';
import { sendFeedback } from '@/services/firebase/functions/sendFeedback';
import { sendErrorLog } from '@/services/firebase/functions/sendErrorLog';

const LINE_HEIGHT = 30;
const MAX_HEIGHT = 30 * 10;

const Feedback = () => {
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [showingMessage, setShowingMessage] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const messageTimeout = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();

  const showMessage = (message: string, time: number) => {
    setMessage(message);
    setShowingMessage(true);
    if (messageTimeout.current) {
      clearTimeout(messageTimeout.current);
    }
    messageTimeout.current = setTimeout(() => {
      setShowingMessage(false);
    }, time);
  };

  const onSend = async () => {
    if (value.trim().length === 0) return;

    setIsActive(false);
    showMessage('Sending...', 1000);
    sendFeedback(value.trim())
      .then(() => {
        showMessage(t('feedback.success'), 1000);
        setValue('');
      })
      .catch((err: unknown) => {
        sendErrorLog(err);
        showMessage(t('feedback.failed'), 10000);
        setIsActive(true);
      });
  };

  const onChangeTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    updateTextareaHeight();
  };

  useEffect(() => {
    updateTextareaHeight();
  }, [value]);

  const updateTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = `${LINE_HEIGHT}px`;
      const height = Math.min(textarea.scrollHeight, MAX_HEIGHT);
      const heightShouldBe = Math.floor(height / LINE_HEIGHT) * LINE_HEIGHT;
      textarea.style.height = `${heightShouldBe}px`;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSend();
    }
  };

  return (
    <>
      {isActive && (
        <div className="fixed z-0 pointer-events-auto inset-0" onClick={() => setIsActive(false)} />
      )}
      {isActive ? (
        <div className="fixed z-10 right-8 bottom-8 pointer-events-auto flex items-center flex-col">
          <div className="bg-white flex items-center border border-qura-neutral-silver py-4 rounded-bigger w-[min(60vw,800px)] shadow-qura">
            <textarea
              className="flex-1 ml-6 font-inter text-medium text-black focus:border-primary focus:outline-none resize-none"
              disabled={!isActive}
              style={{
                lineHeight: `${LINE_HEIGHT}px`,
                height: value.length > 0 ? undefined : `${LINE_HEIGHT}px`,
              }}
              ref={textareaRef}
              value={value}
              onChange={onChangeTextarea}
              onKeyDown={handleKeyDown}
              placeholder={t('feedback.input')}
            />
            <button
              disabled={value.trim().length === 0}
              className={`flex mx-3 items-center justify-center h-8 w-8 bg-qura-neutral-ghost rounded-smaller ${
                value.trim().length > 0
                  ? 'opacity-100 border border-qura-neutral-light '
                  : 'opacity-40'
              }`}
              onClick={onSend}>
              <Icons.ArrowLarge className="h-4 w-4 text-qura-neutral-jet" />
            </button>
          </div>
          {showingMessage && (
            <p className="font-inter font-regular mt-2 text-black text-sm">{message}</p>
          )}
        </div>
      ) : (
        <div className="fixed flex items-center z-10 right-8 bottom-8 pointer-events-auto">
          {
            <p
              className={`font-inter font-regular ${
                showingMessage ? 'opacity-100' : 'opacity-0'
              } transition-opacity duration-1000 mr-5 pointer-events-none`}>
              {message}
            </p>
          }
          <button
            onClick={() => setIsActive(true)}
            className="shadow-qura flex items-center justify-center h-14 w-14 bg-white bg-opacity-90 rounded-lg">
            <Icons.Chat className="h-7 w-7 text-qura-neutral-balanced" />
          </button>
        </div>
      )}
    </>
  );
};

export default Feedback;
