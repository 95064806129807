// THIS FILE TEMPORARILY PATCHES THE STAGES INFORMATION IN THE PROGRESS OBJECT
// REMOVE THIS ONCE THE BACKEND RETURNS THE STAGES

import { SearchProgress } from '@/types/api';

const getStages = (legacyStage: SearchProgress['stage']) => {
  if (legacyStage === 'searching') {
    return {
      searchingDatabases: {
        weight: 1,
        name: 'searchStatus.searchingDatabases',
        status: 'ongoing',
      },
      analyzingAndRanking: {
        weight: 0,
        name: 'searchStatus.analyzingAndRanking',
        status: 'pending',
      },
    };
  }

  if (legacyStage === 'analyzing') {
    return {
      searchingDatabases: {
        weight: 1,
        name: 'searchStatus.searchingDatabases',
        status: 'completed',
      },
      analyzingAndRanking: {
        weight: 0,
        name: 'searchStatus.analyzingAndRanking',
        status: 'ongoing',
      },
    };
  }

  if (legacyStage === 'done') {
    return {
      searchingDatabases: {
        weight: 1,
        name: 'searchStatus.searchingDatabases',
        status: 'completed',
      },
      analyzingAndRanking: {
        weight: 0,
        name: 'searchStatus.analyzingAndRanking',
        status: 'completed',
      },
    };
  }

  return {
    searchingDatabases: {
      weight: 1,
      name: 'searchStatus.searchingDatabases',
      status: 'pending',
    },
    analyzingAndRanking: {
      weight: 0,
      name: 'searchStatus.analyzingAndRanking',
      status: 'pending',
    },
  };
};

export const __temp_mockStages = (progress: SearchProgress | null) => {
  if (!progress) return null;

  return {
    ...progress,
    stages: getStages(progress.stage),
  } as SearchProgress;
};
